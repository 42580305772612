export enum IconType {
  Check = 'check',
  Star = 'star',
  Stars = 'stars',
  Linkedin = 'linkedin',
  Instagram = 'instagram',
  Facebook = 'facebook'
}

export enum SVGIconOption {
  Viewer = 'viewer',
  Markers = 'markers',
  Offline = 'offline',
  Export = 'export',
  MetaData = 'meta-data',
  Zoom = 'zoom',
  Search = 'search',
  Speed = 'speed',
  Stars = 'stars',
  Wallet = 'pricing',
  Info = 'faqs',
  Email = 'contact',
  Phone = 'download',
  Tablet = 'tablet',
  Heart = 'heart',
  Filter = 'filter',
  Import = 'import',
  Map = 'map',
  Trim = 'trim',
  Home = 'home'
}

export type SVGIconType = 'default' | 'circle' | 'square';
