export const appPrivacyPolicy = `
At VIKIN Technologies Ltd (VIKIN), we produce mobile apps (Apps) for a range of outdoor events (Events). These Apps are accessible from the Apple App Store and the Google Play Store. We also host our server-side systems (Platform) that powers the mobile apps.

One of our main priorities is the privacy of our customers (You). This Privacy Policy document contains types of information that is collected and recorded by VIKIN and how we use it.

If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

This Privacy Policy applies only to our App activities and is valid for users of our App with regards to the information that they shared and/or we collect. This policy is not applicable to any information collected via channels other than the VIKIN Apps.

‍

Consent
-------

By using our Apps and Platform, you hereby consent to our Privacy Policy and agree to its terms.

‍

Information we collect
----------------------

The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

‍

**Personally Identifiable Information (PII)**

The VIKIN Apps do not collect any PII. All users are anonymous within the Apps and Infrastructure.

‍

**Location**

We ask for your location information in order to personalise your experience and show content that is nearby to you.

You are able to decline location access when the App asks for your consent. If you decline, functionality that relies on location information will be limited or unavailable.

The location data is not transmitted from your device, all processing occurs on-device.

‍

### Notification Tokens

We ask for a unique push notification token in order to send you notifications related to the Events you’re attending.

You are able to decline access when the App asks for your consent. If you decline, functionality that relies on push notifications will be limited or unavailable.

The push notification token is transmitted from your device to the VIKIN Platform, and to a third Party (OneSignal) to facilitate sending notifications.

‍

### Unique Identifiers

We generate a unique identifier that allows the Platform to distinguish your device & actions whilst using the App. We use a unique identifier to keep your identity private - we cannot use the identifier to determine your identity.

You are not able to decline the unique identifier as it is required for the Platform to function.

The unique identifier is transmitted from your device to the VIKIN Platform, and to third parties (Amplitude and OneSignal) to allow us to distinguish devices from each other whilst maintaining anonymity.

‍

### Search Terms

We track the search terms entered into the App to provide Events with statistical information regarding the content attendees are most interested in.

You should not enter any PII into the app’s search field. We are able to filter PII however this cannot be relied on.

You are able to decline access by changing the consent properties within Analytics Preferences in the App. If you decline, functionality should not change however Events will lose data that allows them to improve and grow.

The search terms are transmitted from your device to the VIKIN Platform, and to third parties (Amplitude) to allow us to analyse the information gathered.

‍

### App Interactions

We track the interactions performed in the App to provide Events with statistical information regarding the content attendees are most interested in.

You are able to decline access by changing the consent properties within Analytics Preferences in the App. If you decline, functionality should not change however Events will lose data that allows them to improve and grow.

The app interactions are transmitted from your device to the VIKIN Platform, and to third parties (Amplitude and Sentry) to allow us to analyse the information gathered.

‍

### Crash Logs

We track crashes & performance related issues in the App to help VIKIN improve the quality of the App and Platform.

You are able to decline access by changing the consent properties within Analytics Preferences in the App. If you decline, functionality should not change however VIKIN may not be able to provide support if you encounter issues with the App or Platform.

The crash logs are transmitted from your device to the VIKIN Platform, and to third parties (Amplitude and Sentry) to allow us to analyse the information gathered.

‍

### Diagnostics

We track diagnostic information in the App to help VIKIN improve the quality of the App and Platform.

You are able to decline access by changing the consent properties within Analytics Preferences in the App. If you decline, functionality should not change however VIKIN may not be able to provide support if you encounter issues with the App or Platform.

The diagnostic information is transmitted from your device to the VIKIN Platform, and to third parties (Amplitude and Sentry) to allow us to analyse the information gathered.

‍

### Performance Data

We track performance metrics within the App to help VIKIN improve the quality of the App and Platform.

You are able to decline access by changing the consent properties within Analytics Preferences in the App. If you decline, functionality should not change however VIKIN may not be able to provide support if you encounter issues with the App or Platform.

The performance data is transmitted from your device to the VIKIN Platform, and to third parties (Amplitude and Sentry) to allow us to analyse the information gathered.

‍

### Contacting Us

If you contact us directly via email, we may receive additional information about you such as your name, email address, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

‍

How we use information collected
--------------------------------

We use the information we collect in various ways, including to:

*   Provide, operate, and maintain our App and Platform
*   Improve, personalise, and expand our App and Platform
*   Understand and analyse how you use our App and Platform
*   Develop new products, services, features, and functionality
*   Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the App, Platform, and Events, and for marketing and promotional purposes
*   Find and prevent fraud

‍

### COOKIES

VIKIN uses ‘cookies’ and other forms of on-device storage to store information including your preferences, settings, and ‘Find My’ locations.

‍

Third Party Privacy Policies
----------------------------

VIKIN’s Privacy Policy does not apply to other apps and websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party services for more detailed information. It may include their practices and instructions about how to opt-out of certain options.

Amplitude (analytics & metrics): [https://amplitude.com/trust](https://amplitude.com/trust) & [https://amplitude.com/privacy](https://amplitude.com/privacy) 

Cloudflare (hosting & infrastructure): [https://www.cloudflare.com/en-gb/privacypolicy/](https://www.cloudflare.com/en-gb/privacypolicy/) 

OneSignal (communications & marketing): [https://onesignal.com/privacy\_policy](https://onesignal.com/privacy_policy) 

Sentry (diagnostics & metrics): [https://sentry.io/privacy/](https://sentry.io/privacy/) 

‍

CCPA Privacy Rights (Do Not Sell My Personal Information)
---------------------------------------------------------

Under the CCPA, among other rights, California consumers have the right to:

*   Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
*   Request that a business delete any personal data about the consumer that a business has collected.
*   Request that a business that sells a consumer's personal data, not sell the consumer's personal data.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

‍

GDPR Data Protection Rights
---------------------------

We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

*   The right to access: You have the right to request copies of your personal data. We may charge you a small fee for this service.
*   The right to rectification: You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
*   The right to erasure: You have the right to request that we erase your personal data, under certain conditions.
*   The right to restrict processing: You have the right to request that we restrict the processing of your personal data, under certain conditions.
*   The right to object to processing: You have the right to object to our processing of your personal data, under certain conditions.
*   The right to data portability: You have the right to request that we transfer the data that we have collected to another organisation, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

‍

Children's information
----------------------

Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

VIKIN does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information within our Apps, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`;
