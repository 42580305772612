import './Navigation.scss';

import React from 'react';

import { ButtonStyleType } from '../../models/button';
import { LogoType } from '../../models/logo';

import { AppLogo } from '../AppLogo/AppLogo';
import { Button } from '../Button/Button';
import { NavigationItem } from '../NavigationItem/NavigationItem';

import { NavigationMenu } from './NavigationMenu/NavigationMenu';

export const Navigation = () => (
  <div>
    <nav className="container-fluid py-3 navigation">
      <div className="container-xxl px-5 navigation-container">
        <div className="row">
          <div className="col-2 col-md-4 col-lg-6 col-xl-7 nav-logo align-content-center">
            <AppLogo type={LogoType.Green}></AppLogo>
          </div>
          <div className="col-10 col-md-8 col-lg-6 col-xl-5 align-items-center justify-content-between nav-desktop">
            <NavigationItem title="Features" link="#features" external={false} />
            <NavigationItem title="FAQs" link="#faqs" external={false}></NavigationItem>
            <NavigationItem title="Contact" link="/book-call" external={false}></NavigationItem>
            <Button styleType={ButtonStyleType.Primary} text="I Want VIKIN" link="/book-call" />
          </div>
          <NavigationMenu />
        </div>
      </div>
    </nav>

    <div className="navigation-holder py-3"></div>
  </div>
);
