import './FAQ.scss';

import React from 'react';

import { FAQItem } from './FAQItem/FAQItem';

export const FAQ = () => (
  <div className="row d-flex justify-content-center position-relative my-5">
    <div id="faqs" className="anchor"></div>
    <h2>FAQs</h2>
    <h3 className="h4 mt-2">The most common questions answered</h3>
    <div className="d-flex flex-column faq-container">
      <FAQItem
        question="Does VIKIN work when offline? My venue has no signal!"
        answer={`We built VIKIN from the ground-up to be offline-first. All data is stored in the app, so as soon as it's installed your attendees with have everything they need.
          
          Our bespoke content distribution platform is proven to work in low-connectivity conditions, so even with poor signal you can still send out updates!`}
      />
      <FAQItem
        question="What makes VIKIN different to other platforms?"
        answer={`VIKIN solves the key problems that events face. Attendees demand high-quality, user-friendly apps with information that is hyper-relevant to where they are, and when they look. Organisers need new revenue streams, efficient tooling (as you've already got enough on!), and the ability to connect with audiences year-round. Sponsors and vendors seek meaningful brand visibility and ROI from partnering with events.`}
      />
      <FAQItem
        question="Is VIKIN proven?"
        answer={`In 2024 VIKIN partnered with The Big Retreat Festival and Dubbed Out Festival to validate the platform and get early feedback. The results were outstanding. High attendee engagement, with significant app adoption across all demographics. Positive feedback from attendees, vendors, event organisers and staff. 99.99+% uptime despite heavy platform utilisation in low-connectivity environments.`}
      />
      <FAQItem
        question="Is it difficult to get started?"
        answer={`Not at all! We work closely with you to ensure you have the support you need to get the most out of the VIKIN platform!`}
      />
      <FAQItem
        question="How much does VIKIN cost?"
        answer={`Our reasonable pricing makes it possible for events of any size to have a world-class app, that doesn't break the bank. We work with you to understand your event, and our pricing is then based off your event's ticketed capacity.`}
      />
      <FAQItem
        question="Won't an app distract from my event?"
        answer={`We don't want our app to be the focus - we designed VIKIN to make it effortless to quickly find the information you need, so you can put away the phone and enjoy the event. Our dashboard is the first thing attendees will see - and with location & time-aware information, they won't need to dig around to find what they want.`}
      />
      <FAQItem
        question="Is VIKIN eco-friendly?"
        answer={`Our platform is built for efficiency. We don't have massive data-centers, our app is designed to preserve battery life, and our business is conscious about preserving our planet for future generations. By using VIKIN, on phones that are already switched on, and not printing setlists / information on paper, your event can move away from single-use setlists.`}
      />
      <FAQItem
        question="I'm already with another platform - why swap?"
        answer={`VIKIN offers a unique event app experience - perfect offline-first functionality, context-aware information, fully branded to match your vibes, intelligent search and more. We can help you migrate your data into our platform, and set you up for success.`}
      />
      <FAQItem
        question="How quickly can I get set up? What's the process"
        answer={`We can get you set up quickly. Book your initial call below. We'll discuss your event and how VIKIN can help. Then, after signing contracts & agreeing payment, we'll get your event and your team onboarded.`}
      />
    </div>
  </div>
);
