import './Feature.scss';

import React from 'react';

import { ImageExtension } from '../../../models/image';
import { Position } from '../../../models/position';

import { Feature as FeatureType } from '../../../constants/features';

import { Image } from '../../Image/Image';

interface Props {
  feature: FeatureType;
  imageLocation: Position;
}

export const Feature = ({ feature, imageLocation }: Props) => {
  const getTextContainer = () => {
    return (
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center feature-text-container flex-column">
        <div className="d-flex feature-text">
          <div className="">
            <h2 className="text-start">{feature.longTitle}</h2>
            <h3 className="h4 text-start feature-subtitle">{feature.longDescription}</h3>
          </div>
        </div>
      </div>
    );
  };

  const getImageContainer = () => {
    //if (!feature.imgAlt) return null;

    return (
      <div className="col-12 col-md-6">
        <div className="bg-light-grey feature-img">
          <Image alt="" img={`/features/screenshots/${feature.key}`} type={ImageExtension.WEBP} />
        </div>
      </div>
    );
  };

  return (
    <div className={`features-item features-${feature.key} features-${imageLocation} position-relative `}>
      <div id={feature.key} className="anchor"></div>
      <div className={`justify-content-around row ${imageLocation === Position.Start ? '' : 'flex-row-reverse'} `}>
        {getImageContainer()}
        {getTextContainer()}
      </div>
    </div>
  );
};
