export const termsOfService = `
Last updated: Nov 25, 2024

**We are VIKIN Technologies Ltd** (“We/Us”), the creators of VIKIN (referred to below as the “App”). You must be at least 13 years old to use the App. By downloading the App from Apple’s software distribution platform and Google’s software distribution platform (“the App Store”), you are agreeing to the terms and conditions stated in this end-user licence agreement (the “EULA”) and the rules and policies of the App Store, which are legally binding. Please read them before you download and use the App. Only download the App if you have read the rules and agree to them. If you do not agree to these terms, you should not download or use the App.

1.  **The App.**

    1.1 The App is a piece of software made accessible to you as an attendee of an event that VIKIN is serving, and is customised for use with iOS and Android mobile devices ('Devices'). It is intended for use by those attending the event to provide helpful information, let you know when performances start and to help you find places on the festival site.
2.  **This EULA**
    
    2.1 We licence you to download and use the App, provided that you follow the rules described in this EULA. The non-exclusive, non-transferable, non-sublicensable licence:  
    2.1.1 is only for you personally (and anyone else that the App Store lets you share the App with);  
    2.1.2 starts when you download the App and ends when the App is no longer available; and  
    2.1.3 covers content, materials, or services accessible from, the App including our support resources. It also covers updates to the App unless they come with separate terms, in which case we will give you an opportunity to review and accept the new terms.  
    2.2 You do not own the App or any of its content but you may use it on devices that you own or control, as permitted by the App Store rules.  
      
    2.3 If you sell or give away the device on which you have downloaded the App, you must first remove the App from the device.  
      
    2.4 You are not allowed to:  
    2.4.1 modify the App’s code in any way, including inserting new code, either directly or through the use of another App or piece of software;  
    2.4.2 deliberately attempt to avoid or manipulate any security features included in the App; or  
    2.4.3 pretend that the App is your own or make it available for others to download or use (including by way of copying the code of the App and creating an independent version).
3.  **Technical requirements & updates to the App**

    3.1 You acknowledge that it is Your responsibility to confirm and determine that the device on which You intend to use the App satisfies the technical specifications specified on our website. We reserve the right to modify the technical specifications as we see fit at anytime.  
      
    3.2 We may update the App from time to time for reasons that include fixing bugs or enhancing functionality. We might also change or remove functionality, but we will ensure that the App still materially meets the description of it that was provided to you at the time you downloaded the App.  
      
    3.3 We may update the App so that it complies with modified/new versions of the firmware and new hardware but shall not be obliged, whether expressly or by implication, to provide any maintenance, technical or other support for the App. You are not granted rights to claim such an update.  
      
    3.4 Updates will either download automatically or you may need to trigger them yourself, depending on your device, its settings and the App Store.  
      
    3.5 We strongly recommend that you download all updates and latest versions of the App as soon as they become available. Depending on the nature of the update, the App may not work properly(or at all), or you may be exposed to security vulnerabilities, if you do not keep the App updated to the latest version that we make available.
4.  **Privacy and your personal information**  
    4.1 The App is for information only and as such we do not collect any personal information from you.  
      
    4.2 The App uses session tokens which are required to allow the App to function correctly, but these tokens do not collect any information from your device and do not collect any personal information about you.
5.  **Location data**  
    5.1 The App makes use of functionality on your device that can pinpoint your location. We do this to allow you to use the mapping functionality and to highlight places near to your location which may be of interest to you.  
    ‍  
    5.2  When you open the App for the first time, you will be asked whether the App can use your location and in what circumstances.  
    ‍  
    5.3 You can change your choices at anytime in the App settings, and you can also turn the location services off at any time. The App will still function if location services are off, but its functionality may be reduced.
6.  **Acceptable use**  
    6.1 You must not use the App to do any of the following things:  
    6.1.1 break the law or encourage any unlawful activity;  
    6.1.2 infringe our or anyone else’s intellectual property rights (for example, by using or uploading someone else’s content);  
    6.1.3 transmit any harmful software code such as viruses;  
    6.1.4 reverse engineer, translate, disassemble, integrate, de compile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the App, or any part thereof;  
    6.1.5 try to gain unauthorised access to computers, data, systems, accounts or networks; or  
    6.1.6 deliberately disrupt the operation of anyone’s website, App, server or business.
7.  **External services**  
    7.1 The App may enable you to access services and websites that we do not own or operate (referred to below as ‘external services’).  
    ‍  
    7.2 We are not responsible for examining or evaluating the content or accuracy of these external services.Before using them, make sure you have read and agreed to the terms on which they are being offered to you, including the way in which they may use your personal information.  
    ‍  
    7.3 You must not use external services in any way that:  
    7.3.1 is inconsistent with these terms or with the terms of the external service; or  
    7.3.2 infringes our intellectual property rights, or the intellectual property rights of any third party.  
    
    7.4 From time to time, we may change or remove any external services that are made available through the App.
8.  **No Warranty**
    
    Use of the App is at your sole risk and responsibility. To the extent permitted by law, the App and any functionality performed or provided by it or in connection with it are provided“as is” and “as available”, without warranty of any kind, and we do not accept any responsibility with respect to the App and any of its functionality whether expressly stated or implied and including but not limited to the implied warranties under the Sale of goods Act and warranties as to quality, fitness for purpose, accuracy, or non-infringement of third-party rights.  
    
9.  **Our Liability**
    
    9.1 We are not liable to you for any loss or damage that was not foreseeable, any loss or damage not caused by our breach or negligence, or any loss or damage.  
    ‍  
    9.2 We shall not be liable for any form of data loss whether directly or indirectly caused by the App. It is your sole responsibility to make backups of your data to avoid data loss.  
      
    9.3 Nothing in these terms excludes or limits our liability for any death or personal injury caused by our negligence, liability for fraud or fraudulent misrepresentation, or any other liability that the law does not allow us to exclude or limit.  
    
10. **Failures of networks or hardware**
    
    External factors that the App requires to work, such as your internet connection, your device and the App Store, are entirely outside of our control. We are not responsible if you are unable to use all or any part of the App due to a poor internet connection, faulty components in your device, App Store failure or anything else that it would not be reasonable to expect us to control.  
    
11. **Changes to this EULA**
    
    11.1 We may need to revise this EULA from time to time to reflect changes in the App’s functionality, if there is a change in the law or guidance or for some other reason.  
 
    11.2 You will be asked to agree to any material changes in advance via a push notification to your device or by an in-app notification, usually when you download an update. If you do not accept the changes, you will not be able to use the App.  
    
12. **Ending this EULA**

    12.1 We can end this EULA if you do not comply with any part of it.  
      
    12.2 We will give you a reasonable amount of notice before the EULA ends but if what you have done is serious then we may end this EULA immediately and without advance notice to you. ‘Serious’ means that you are causing harm (or attempting to cause harm) to other users, interfering with the operation of the App or doing anything else that we think presents a big enough risk to justify us ending the EULA quickly.  
      
    12.3 The consequences of the EULA ending are as follows:  
    12.3.1 you are no longer allowed to use the App and we may remotely limit your access to it;  
    12.3.2 you must delete it from any devices that it has been installed on; and  
    12.3.3 we may delete or suspend access to any accounts that you hold with us.  
    
13. **Third parties**
    
    You acknowledge that other than VIKIN Technologies Ltd and You, the terms of this EULA may also be enforced byGoogle and Apple and their applicable subsidiaries as a third-party beneficiary to this EULA.  
    
14. **Transferring this EULA**
    
    14.1 We may transfer our rights under this EULA to another business without your consent. You are not allowed to transfer your rights under this EULA to anyone without our prior written consent.  
    
15. **Governing law and jurisdiction**
    
    15.1 The laws of England and Wales apply to this EULA, although if you are resident elsewhere you will retain the benefit of any mandatory protections given to you by the laws of that country.Any disputes will be subject to the non-exclusive jurisdiction of the courts ofEngland and Wales. This means that  
    
16. **Support and contact**
    
    16.1 If you need to get in touch with us, please refer to our support page on our website: [https://www.vikin.events](https://www.vikin.events) or via email at: [support@vikin.events.](mailto:support@vikin.events?subject=EULA)`;
