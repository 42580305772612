export enum FeatureTypeKey {
  Bespoke = 'bespoke',
  Search = 'search',
  Notifications = 'notifications',
  Map = 'map',
  Vendors = 'vendors',
  Setlist = 'setlist'
}

export interface Feature {
  key: FeatureTypeKey;
  longTitle: string;
  shortTitle: string;
  footerTitle: string;
  longDescription: string;
  textListOnly?: boolean;
}

export const BespokeFeature: Feature = {
  key: FeatureTypeKey.Bespoke,
  longTitle: 'Fully Featured, Branded, Offline-First',
  shortTitle: 'Fully Featured Branded Apps',
  footerTitle: 'Fully Featured Branded Apps',
  longDescription:
    'Your event deserves a world-class app. We work closely with you to bring your brand into the VIKIN platform - making sure it looks stunning on mobile. Set the best impression with an app that is designed from the ground-up to work perfectly offline'
};

export const SearchFeature: Feature = {
  key: FeatureTypeKey.Search,
  longTitle: 'Intelligent Searching Across Your App!',
  shortTitle: 'Intelligent Search',
  footerTitle: 'Intelligent Search',
  longDescription:
    'Find anything - vendors, artists, locations, FAQs - offline, instantly. You name it, you can search it. Our search combines keywords with location & time relevancy for the perfect results'
};

export const NotificationsFeature: Feature = {
  key: FeatureTypeKey.Notifications,
  longTitle: 'Get News Out - Fast',
  shortTitle: 'Notifications',
  footerTitle: 'Notifications',
  longDescription:
    'Send geo-fenced notifications, get the word out with marketing messages, update attendees with schedule changes, and advise visitors with safety updates - in a way that works in low-connectivity environments'
};

export const MapFeature: Feature = {
  key: FeatureTypeKey.Map,
  longTitle: 'Interactive Maps With Offline Navigation',
  shortTitle: 'Interactive Maps',
  footerTitle: 'Interactive Map',
  longDescription:
    'Responsive, fluid, and insightful: Attendees can explore the event site effortlessly, with rich location details and intuitive filters for seamless exploration. With built-in offline navigation, you can give your attendees a helping hand finding their way around'
};

export const VendorFeature: Feature = {
  key: FeatureTypeKey.Vendors,
  longTitle: 'Make Your Vendors And Sponsors Feel Seen!',
  shortTitle: 'Vendors & Sponsors',
  footerTitle: 'Vendors & Sponsors',
  longDescription:
    'Provide meaningful visibility and ROI to your vendors and sponsors. VIKIN surfaces nearby vendors on the dashboard & in search results, and shows sponsors in-line amongst other relevant content'
};

export const SetlistFeature: Feature = {
  key: FeatureTypeKey.Setlist,
  longTitle: 'See The Lineup',
  shortTitle: 'Setlist',
  footerTitle: 'Setlist',
  longDescription:
    "With your VIKIN app, your interactive setlist is in your attendees' hands. Our platform gives artists as space to shine, with detailed profiles that encourage discoverability. When plans change, just update the VIKIN platform and your attendees will know immediately"
};

export const AllFeatures: Feature[] = [
  BespokeFeature,
  SearchFeature,
  NotificationsFeature,
  MapFeature,
  VendorFeature,
  SetlistFeature
];

const half = Math.ceil(AllFeatures.length / 2);

export const FeaturesLeftHalf: Feature[] = AllFeatures.slice(0, half);
export const FeaturesRightHalf: Feature[] = AllFeatures.slice(half);
