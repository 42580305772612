import React from 'react';

import { WEWANT_EMAIL } from '../../utils/constants';

export const Contact = () => {
  return (
    <div className="row d-flex justify-content-center position-relative my-5">
      <div id="contact" className="anchor"></div>
      <h2>Book Your Free Call</h2>
      <h6
        className="h6 px-5 pb-3 pt-2"
        style={{
          textAlign: 'center'
        }}>
        Book a call below, or drop us an email at{' '}
        <a className="h6" href={`mailto:${WEWANT_EMAIL}`}>
          {WEWANT_EMAIL}
        </a>{' '}
        and we'll get back to you!
      </h6>
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3OscCEXEN-rYzJxs6oEaBu2y4SvOC64dyLXkqL8Ni2KqZVuXtUUoBs6e7ZmnF7Os08L-S5wKWY?gv=true"
        width="100%"
        height="900px"
        frameBorder="0"></iframe>
    </div>
  );
};
